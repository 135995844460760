import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponsePagination } from 'infra/api/type';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { paths } from 'infra/api/schema';
import { getLoginInfo } from 'context/auth/api/login';

export const URL = '/tenant/my/objective/objective_records/';
export type ResponseObjectiveRecord = SnakeObjToCamelObj<
  Required<
    paths[typeof URL]['get']['responses'][200]['content']['application/json']
  >['results'][number]
> & {
  onsiteUser: {
    wealthResultProfile?: string;
  };
};

export const useFetchObjectiveRecords = (
  onsiteUserId: string,
  useCache = false
) => {
  const response = useAxios<ResponsePagination<ResponseObjectiveRecord>>(
    {
      url: MY_TENANT_URL('objective/objective_records/'),
      params: {
        has_created_objective: true,
        onsite_user: onsiteUserId,
        limit: 9999,
      },
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);
  return response;
};

export const useFetchObjectiveRecord = (id?: string, useCache = false) => {
  const response = useAxios<ResponseObjectiveRecord>(
    {
      url: MY_TENANT_URL(`objective/objective_records/${id}/`),
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);
  return response;
};

/** 対象のマネジャー評価者が評価しなければならない中間評価・最終評価を検索 */
export const useFetchObjectiveRecordsRequiredForOutsideManager = (
  outsideManagerId: string,
  now: Date,
  useCache = false
) => {
  const responseForMiddle = useAxios<
    ResponsePagination<ResponseObjectiveRecord>
  >(
    {
      url: MY_TENANT_URL('objective/objective_records/'),
      params: {
        onsite_user__outside_manager__id: outsideManagerId,
        is_completed_middle_evaluation_by_myself: 'true',
        is_completed_middle_evaluation_by_boss: 'false',
        // 中間上司評価期間をすでに開始していること
        evaluation_period__intermediate_boss_evaluation_started_at__lt: now,
        limit: 9999,
      },
    },
    { useCache }
  );
  const responseForFinal = useAxios<
    ResponsePagination<ResponseObjectiveRecord>
  >(
    {
      url: MY_TENANT_URL('objective/objective_records/'),
      params: {
        onsite_user__outside_manager__id: outsideManagerId,
        is_completed_final_evaluation_by_myself: 'true',
        is_completed_final_evaluation_by_boss: 'false',
        // 最終上司評価期間をすでに開始していること
        evaluation_period__final_boss_evaluation_started_at__lt: now,
        limit: 9999,
      },
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(responseForMiddle[0].error);
  useNonFieldApiErrorCallback(responseForFinal[0].error);
  return { middle: responseForMiddle, final: responseForFinal };
};
