import { PropsWithChildren } from 'react';

export const ScoreRow = (
  props: PropsWithChildren<{ score?: number; previewScore?: number }> = {
    score: 85.5,
    previewScore: 56.5,
  }
) => (
  <div className="flex gap-[10px] items-center">
    <div className="rounded border-1 border-main-blue px-[6px] py-[4px]">
      <p className="text-main-blue text-micro font-bold">評価スコア</p>
    </div>

    <p className="text-score">{props.score}</p>
    <p className="text-ui pt-[4px]">点</p>

    <div className="rounded flex items-center pt-[3px] pb-[4px] px-[6px] bg-grayscale-500 leading-none">
      <p className="text-micro text-grayscale-700 pt-[2px]">前回評価スコア</p>
      {props.previewScore === undefined ? (
        <p className="text-micro text-grayscale-700 pt-[2px]">なし</p>
      ) : (
        <>
          <p className="text-base font-medium text-grayscale-700 pt-[4px]">
            {props.previewScore}
          </p>
          <p className="text-micro text-grayscale-700 pt-[2px]">点</p>
        </>
      )}
    </div>
  </div>
);
