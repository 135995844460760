import { FC, PropsWithChildren } from 'react';

import { TableCell } from '@mui/material';
import { SortUpIcon } from 'infra/components/UI/Icons/SortUpIcon';
import { SortDownIcon } from 'infra/components/UI/Icons/SortDownIcon';
import { Types } from './Pagination/Pagination';

interface Props {
  pagerState?: Types.PagerState;
  orderKey?: string;
  orderValue?: string;
}

/**
 * ソート可能なテーブルヘッダーセル
 * pagerStateが渡された場合、ソート機能が有効になり
 * キーと値でソートがかかるようになります。
 *
 * ### 動作仕様
 * ソート間の検索はリセットされます
 * ソートと店舗名などの検索はand検索
 * ソートは矢印を選択すると発火するタイプ
 */
export const TableHeaderCell: FC<PropsWithChildren<Props>> = ({
  pagerState,
  orderKey = 'ordering',
  orderValue,
  children,
}) => {
  return (
    <TableCell className="!py-[9px]">
      <div className="flex items-center">
        <p className="text-base text-grayscale-700 font-normal whitespace-nowrap">
          {children}
        </p>
        {pagerState && (
          <div className="flex flex-col">
            {/* 矢印が上向きが昇順 */}
            <button
              type="button"
              aria-label="昇順にする"
              className="pt-[8px] px-[10px] pb-[3px]"
              onClick={() => {
                pagerState?.setSearchParams((prev: any) => {
                  prev.set(orderKey, orderValue);
                  return prev;
                });
              }}
            >
              <SortUpIcon />
            </button>
            {/* 矢印が下向きが降順 */}
            <button
              type="button"
              aria-label="降順にする"
              className="pb-[8px] px-[10px] pt-[3px]"
              onClick={() => {
                pagerState?.setSearchParams((prev: any) => {
                  prev.set(orderKey, `-${orderValue}`);
                  return prev;
                });
              }}
            >
              <SortDownIcon />
            </button>
          </div>
        )}
      </div>
    </TableCell>
  );
};
