import useAxios from 'axios-hooks';

import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponsePagination } from 'infra/api/type';

export const LIST_URL = '/tenant/my/user/onsite_users/';
export const DETAIL_URL = '/tenant/my/user/onsite_users/{id}/';
export type OnsiteUserListResponse = SnakeObjToCamelObj<
  Required<
    paths[typeof LIST_URL]['get']['responses'][200]['content']['application/json']
  >['results'][number]
> & {
  evaluationAchievementPercent?: number;
  wealthProfileResult?: string;
};
export type OnsiteUserDetailResponse = SnakeObjToCamelObj<
  paths[typeof DETAIL_URL]['get']['responses'][200]['content']['application/json']
> & {
  wealthProfileResult?: string;
};

/**
 * ユーザー情報の一覧取得
 * @param useCache
 * @returns
 */
export const useGetOnsiteUsers = (
  isManagerOnly?: boolean,
  useCache = false
) => {
  const response = useAxios<ResponsePagination<OnsiteUserListResponse>>(
    {
      url: LIST_URL,
      params: {
        limit: 99999,
        is_manager: isManagerOnly,
      },
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};

/**
 * ユーザー情報の一覧取得
 */
export const fetchOnsiteUsers = (
  limit: number,
  offset: number,
  searchParams: URLSearchParams,
  signal?: AbortSignal
) => {
  const params = {
    limit,
    offset,
    ordering: 'shop__name,last_name,first_name',
    ...Object.fromEntries(searchParams.entries()),
  };
  const response = apiClient.get<ResponsePagination<OnsiteUserListResponse>>(
    LIST_URL,
    {
      params,
      signal,
    }
  );

  return response;
};

/**
 * ユーザー情報の詳細取得
 * @param useCache
 * @returns
 */
export const useGetOnsiteUser = (id: string, useCache = false) => {
  const response = useAxios<OnsiteUserDetailResponse>(
    {
      url: DETAIL_URL.replace('{id}', id),
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};
