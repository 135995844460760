import { FC } from 'react';
import { ExclamationBlueIcon } from 'infra/components/UI/Helps/ExclamationBlueIcon';

export type TooltipContent = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name?: string | null;
  amount: number;
  onsiteUserHr: number;
};

type TooltipContentTypes = {
  content: TooltipContent[];
};

export const Tooltip: FC<TooltipContentTypes> = ({ content }) => {
  const renderContent = () => {
    return content.map((item) => (
      <div key={item.id} className="text-grayscale-100 text-meta">
        {item.name ?? '未定義'} {item.amount}
      </div>
    ));
  };

  return (
    <div className="relative group cursor-pointer px-2 py-1">
      <span
        className="
          px-[12px] py-[6px] absolute left-0 top-0
          transform translate-y-[calc(-100%-6px)]
          bg-grayscale-800 text-grayscale-100 rounded whitespace-nowrap
          before:content-[''] before:block
          before:absolute before:left-[11px] before:top-full
          before:w-0 before:h-0 before:border-t-4 before:border-l-4 before:border-r-4 before:border-transparent before:border-t-grayscale-800
          opacity-0 transition pointer-events-none
          group-hover:opacity-100"
      >
        {renderContent()}
      </span>
      <ExclamationBlueIcon />
    </div>
  );
};
