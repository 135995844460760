import { PropsWithChildren, memo } from 'react';
import { CheckIcon } from 'context/objective/components/ObjectiveRecordList/CheckIcon';

import NoteIcon from 'context/objective/assets/note_icon01.svg';
import { Tooltip } from 'context/objective/components/ObjectiveRecordList/_components/Tooltip';
import { ScoreRow } from './ScoreRow';
import { PromitionSwitch } from './PromotionSwitch';
import { ObjectiveRecord, PromotionLog } from '.';
import { OnsiteUserName } from './OnsiteUserName';
import { OnsiteUserAffiliation } from './OnsiteUserAffiliation';

export const OnsiteUserCard = memo(
  (
    props: PropsWithChildren<{
      objectiveRecord: ObjectiveRecord;
      promotionLog?: PromotionLog;
    }>
  ) => {
    const { objectiveRecord } = props;
    const { onsiteUser } = objectiveRecord;

    return (
      <div className="relative">
        <div className="p-[20px]">
          <OnsiteUserName
            fullName={`${onsiteUser.lastName} ${onsiteUser.firstName}`}
            image={onsiteUser.avatar || ''}
            isWithdrawal={onsiteUser.appUsageState === 'withdrawal'}
          />

          <OnsiteUserAffiliation
            shopName={onsiteUser.shopName}
            occupationName={onsiteUser.occupationName}
            gradeName={onsiteUser.gradeName}
            positionName={onsiteUser.gradePositionName}
            isTextCenter={false}
          />

          <div className="pt-[14px]">
            {objectiveRecord.score ? (
              <ScoreRow
                score={objectiveRecord.score}
                previewScore={objectiveRecord.previousScore || undefined}
              />
            ) : (
              <>
                <div className="inline-flex items-center py-[4px] px-[10px] gap-[6px] bg-soft-yellow rounded-full">
                  <img src={NoteIcon} alt="ビックリマークのアイコン" />
                  <p className="text-label text-grayscale-700 font-bold">
                    評価スコア反映待ちです
                  </p>
                </div>
                <ul className="flex items-center gap-[24px] mt-[20px]">
                  <li className="flex flex-col gap-[10px] items-start leading-none">
                    <p
                      className="
                        w-auto p-[5px] border-1 border-main-blue text-main-blue text-micro font-bold rounded text-center
                      "
                    >
                      前期評価スコア
                    </p>
                    <div className="flex items-center gap-[6px]">
                      <p className="text-score font-bold">-</p>
                      <p className="text-label">スコア未定義</p>
                    </div>
                  </li>
                  <li className="flex flex-col gap-[10px] items-start leading-none">
                    <p
                      className="
                        w-auto p-[5px] border-1 border-main-blue text-main-blue text-micro font-bold rounded text-center
                      "
                    >
                      後期評価スコア
                    </p>
                    <div className="flex items-center gap-[6px]">
                      <p className="text-score font-bold">-</p>
                      <p className="text-label">スコア未定義</p>
                    </div>
                  </li>
                </ul>
              </>
            )}
          </div>

          <div className="flex justify-between items-center pt-[14px]">
            {objectiveRecord.promotionLog !== null && (
              <div className="min-w-[240px] flex flex-col items-start">
                <PromitionSwitch
                  readonly
                  setSalary={() => {}}
                  salary={props.promotionLog!.salary!}
                  isPromoted={props.promotionLog!.isPromoted}
                  setIsPromoted={() => {}}
                  nextGradeWageType={objectiveRecord.nextGradeWageType}
                />
                {props.promotionLog!.isTemporaryFixed && (
                  <div className="absolute right-[20px] bottom-[20px] w-[24px] h-[24px]">
                    <CheckIcon />
                  </div>
                )}
                {props.promotionLog!.isPromoted && (
                  <div className="ml-auto mt-[10px]">
                    <div className="flex items-center gap-[4px]">
                      {objectiveRecord.onsiteUser.allowances.length > 0 && (
                        <>
                          <Tooltip
                            content={objectiveRecord.onsiteUser.allowances}
                          />
                          <span className="text-label text-main-blue font-bold">
                            手当{' '}
                            {objectiveRecord.onsiteUser.allowances
                              .reduce(
                                (total, allowance) => total + allowance.amount,
                                0
                              )
                              .toLocaleString()}
                            円を含む
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
