import { Dialog } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { CalendarMonthIcon } from 'infra/components/UI/Icons/CalendarMonthIcon';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';

import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { formatDate } from 'infra/utils/date-fns';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { STYLES, COLORS } from 'tailwind.config';
import { Tooltip } from 'context/objective/components/ObjectiveRecordList/_components/Tooltip';
import { LABEL_MAP, ObjectiveRecord, PromotionLog, UserType } from '.';
import { PromitionSwitch } from './PromotionSwitch';
import { EvaluationCard } from './EvaluationCard';
import { ScoreRow } from './ScoreRow';
import { OnsiteUserAffiliation } from './OnsiteUserAffiliation';
import { OnsiteUserName } from './OnsiteUserName';

type Grade = {
  currentGradeName: string;
  nextGradeName: string;
};

export type EditingDataType = {
  salary: number | null;
  isPromoted: boolean;
  isTemporaryFixed: boolean;
};

export const PromotionDetailModal = (
  props: PropsWithChildren<{
    promotionLog: PromotionLog;
    objectiveRecord: ObjectiveRecord;
    grade: Grade;
    userType: UserType;
    onClose: () => void;
    onSave: (editedData: EditingDataType) => void;
  }>
) => {
  const { objectiveRecord, promotionLog } = props;

  const [editingPromotionLog, setEditingPromotionLog] =
    useState<EditingDataType>({
      salary: props.promotionLog?.salary || null,
      isPromoted: props.promotionLog?.isPromoted || false,
      isTemporaryFixed: props.promotionLog?.isTemporaryFixed || false,
    });

  useEffect(() => {
    if (
      editingPromotionLog.isPromoted &&
      (editingPromotionLog.salary === 0 || editingPromotionLog.salary === null)
    ) {
      setEditingPromotionLog({
        ...editingPromotionLog,
        salary: objectiveRecord.nextGradeSalaryIncludeingAllowances,
      });
    }
  }, [editingPromotionLog.isPromoted]);

  // 不明な場合は読み取り専用
  const isReadonly = promotionLog === undefined ? true : promotionLog.isFixed;
  const isWithdrawal =
    objectiveRecord.onsiteUser.appUsageState === 'withdrawal';

  let buttonText = '退職者のため保存ができません';
  if (!isWithdrawal) {
    buttonText = isReadonly ? '既に確定しているため保存できません' : '保存';
  }

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            disabled={isWithdrawal || isReadonly}
            className="w-btn-small text-btn-confirm p-button-small whitespace-nowrap"
            style={{
              backgtoundColor:
                isWithdrawal || isReadonly ? COLORS.GRAY_700 : '',
              width: isWithdrawal || isReadonly ? STYLES.WIDTH.BTN_CONFIRM : '',
              fontSize:
                isWithdrawal || isReadonly ? STYLES.FONT_SIZE.BTN_LARGE : '',
            }}
            onClick={() => props.onSave(editingPromotionLog)}
          >
            {buttonText}
          </BaseButton>
        }
        closeHandler={props.onClose}
      >
        <div>
          <h3 className="text-section-heading font-bold">
            {LABEL_MAP[props.userType]}
            最終評価完了
          </h3>
          <div className="grid grid-cols-2 pt-[40px]">
            <div>
              <OnsiteUserName
                image={objectiveRecord.onsiteUser.avatar || ''}
                fullName={`${objectiveRecord.onsiteUser.lastName} ${objectiveRecord.onsiteUser.firstName}`}
              />
              <OnsiteUserAffiliation
                shopName={objectiveRecord.onsiteUser.shopName}
                occupationName={objectiveRecord.onsiteUser.occupationName}
                positionName={objectiveRecord.onsiteUser.gradePositionName}
                gradeName={objectiveRecord.onsiteUser.gradeName}
                wealthProfileResult={
                  objectiveRecord.onsiteUser.wealthResultProfile
                }
                isTextCenter={false}
              />
            </div>
            <div>
              <div className="flex items-center gap-[10px]">
                <CalendarMonthIcon />
                <p className="text-base text-grayscale-700">
                  {formatDate(
                    new Date(objectiveRecord.evaluationPeriod.startedAt)
                  )}
                  ~
                  {formatDate(
                    new Date(objectiveRecord.evaluationPeriod.endedAt)
                  )}
                </p>
              </div>
              <div className="pt-[16px]">
                <ScoreRow
                  score={objectiveRecord!.score!}
                  previewScore={objectiveRecord.previousScore || undefined}
                />
              </div>
              <div className="flex flex-col items-start pt-[14px] max-w-[240px]">
                {promotionLog !== undefined ? (
                  <PromitionSwitch
                    readonly={isWithdrawal || isReadonly}
                    isPromoted={editingPromotionLog.isPromoted}
                    setIsPromoted={(value) => {
                      setEditingPromotionLog({
                        ...editingPromotionLog,
                        isPromoted: value,
                      });
                    }}
                    salary={editingPromotionLog.salary}
                    setSalary={(value) => {
                      setEditingPromotionLog({
                        ...editingPromotionLog,
                        salary: value,
                      });
                    }}
                    nextGradeWageType={objectiveRecord.nextGradeWageType}
                  />
                ) : (
                  <p className="text-base">
                    評価スコアが決まっていないため昇給記録をつけることができません
                  </p>
                )}
                {editingPromotionLog.isPromoted && (
                  <div className="ml-auto mt-[10px]">
                    <div className="flex items-center gap-[4px]">
                      {objectiveRecord.onsiteUser.allowances.length > 0 && (
                        <>
                          <Tooltip
                            content={objectiveRecord.onsiteUser.allowances}
                          />
                          <span className="text-label text-main-blue font-bold">
                            手当{' '}
                            {objectiveRecord.onsiteUser.allowances
                              .reduce(
                                (total, allowance) => total + allowance.amount,
                                0
                              )
                              .toLocaleString()}
                            円を含む
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center pt-[16px]">
                <p className="text-label mr-[6px]">現在の等級</p>
                <p className="text-meta mr-[16px]">
                  {props.grade.currentGradeName}
                </p>
                <p className="text-meta font-medium mr-[16px]">→</p>
                <p className="text-label text-main-blue mr-[6px]">
                  昇給後の等級
                </p>
                <p className="text-meta text-main-blue font-bold">
                  {props.grade.nextGradeName}
                </p>
              </div>
            </div>
          </div>

          <Divider className="mt-[30px]" />

          <div className="grid grid-cols-2 pt-[40px] gap-[36px]">
            {objectiveRecord.basicThinkings.map((basicThinking) => {
              return (
                <div>
                  <EvaluationCard
                    color={basicThinking.basicThinkingMaster.categoryColor}
                    basicThinkingLabel={
                      basicThinking.basicThinkingMaster.basicThinkingLabel
                    }
                    finalMyselfEvaluation={
                      basicThinking.finalEvaluationMyScore ?? undefined
                    }
                    finalBossEvaluation={
                      basicThinking.finalEvaluationBossScore ?? undefined
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </BaseModal>
    </Dialog>
  );
};
