/**
 * 新規スタッフを登録する処理関連のステート
 */

import { z } from 'zod';
import {
  Message,
  useForm,
  Control,
  UseFormWatch,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFormSetError,
  FormState,
  UseFormRegister,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { StaffFormSchema } from 'context/users/onsite_users/_infra/components/StaffForm';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';

import {
  hasNonFieldError,
  useDisplayNonFieldApiError,
} from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { postOnsiteuser } from 'context/users/onsite_users/_infra/api/onsiteUser.post';

/**
 * Types
 */
export namespace Types {
  export type State = {
    startLoading: (key: string) => void;
    stopLoading: (key: string) => void;
    displayMessage: (message: Message) => void;
    displayNonFieldApiError: (error: any) => void;
    register: UseFormRegister<TypeStaffForm>;
    handleSubmit: UseFormHandleSubmit<TypeStaffForm>;
    control: Control<TypeStaffForm, any>;
    setError: UseFormSetError<TypeStaffForm>;
    watch: UseFormWatch<TypeStaffForm>;
    setValue: UseFormSetValue<TypeStaffForm>;
    formState: FormState<TypeStaffForm>;
  };

  type TypeStaffForm = z.infer<typeof StaffFormSchema>;
}

/**
 * `state` には新規スタッフ登録に関する状態があります。
 * この新規スタッフ登録に関連する状態管理はここを参照してください。
 * この新規スタッフ登録に状態を追加する場合は、ここ以外に記述しないでください。
 *
 * @returns {object} 状態管理に必要な変数と関数を含むオブジェクト
 */
export const State = () => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm({
    resolver: zodResolver(StaffFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      phoneNumber: '',
      email: '',
      isManager: false,
      sex: '0',
      yearOfjoinedAt: '2010',
      monthOfjoinedAt: '01',
      dayOfjoinedAt: '01',
      salary: 0,
      employmentStatus: '',
      allowances: [{ name: '', amount: 0 }],
      occupationId: 0,
      subOccupationId: null,
      gradeId: 0,
      shopId: '',
      bossId: '',
      outsideManagerId: '',
      isEvaluableShops: false,
      evaluableShops: [{ shopId: '' }],
      yearOfunsubscribeAt: undefined,
      monthOfunsubscribeAt: undefined,
      dayOfunsubscribeAt: undefined,
    } as z.infer<typeof StaffFormSchema>,
  });

  const onCreate = useFormInstance.handleSubmit(
    (value: z.infer<typeof StaffFormSchema>) => {
      startLoading('create_staff');

      value.evaluableShops.forEach((item, index) => {
        if (!item.shopId) {
          value.evaluableShops.splice(index, 1);
        }
      });
      if (value.outsideManagerId === '' && value.bossId === '') {
        displayMessage({
          text: '評価者1もしくは2のいずれかを設定してください',
          isInterruption: true,
        });
        stopLoading('create_staff');
        return;
      }

      const bossIdWithType = JSON.parse(value.bossId);
      const joinedAt = `${
        value.yearOfjoinedAt
      }-${value.monthOfjoinedAt.padStart(
        2,
        '0'
      )}-${value.dayOfjoinedAt.padStart(2, '0')}`;

      let unsubscribeAt = null;

      if (
        value.yearOfunsubscribeAt &&
        value.monthOfunsubscribeAt &&
        value.dayOfunsubscribeAt
      ) {
        unsubscribeAt = `${
          value.yearOfunsubscribeAt
        }-${value.monthOfunsubscribeAt.padStart(
          2,
          '0'
        )}-${value.dayOfunsubscribeAt.padStart(2, '0')} 00:00:00+09:00`;
      }
      
      postOnsiteuser({
        ...value,
        joinedAt,
        unsubscribeAt,
        gradeId: value.gradeId,
        occupationId: value.occupationId,
        subOccupationId: value.subOccupationId!,
        bossId: bossIdWithType.type === 'manager' ? bossIdWithType.id : null,
        outsideManagerId:
          bossIdWithType.type === 'outside_manager' ? bossIdWithType.id : null,
      })
        .then(() => {
          displayMessage({
            text: 'スタッフを登録しました',
            isInterruption: false,
          });
          navigate('/staffs_manage/staffs/');
        })
        .catch((error: any) => {
          if (hasNonFieldError(error)) {
            displayNonFieldApiError(error);
          }
          const errorDetailMap = error.response?.data as {
            [key: string]: string[];
          };
          if (errorDetailMap) {
            Object.keys(errorDetailMap).forEach((key) => {
              useFormInstance.setError(key as any, {
                type: 'custom',
                message: errorDetailMap[key][0],
              });
            });
          }
        })
        .finally(() => {
          stopLoading('create_staff');
        });
    },
    console.error
  );

  return {
    useForm: useFormInstance,
    startLoading,
    stopLoading,
    displayMessage,
    displayNonFieldApiError,
    navigate,
    onCreate,
  };
};
