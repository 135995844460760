import { Checkbox, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { useEffect } from 'react';
import {
  OnsiteUserListResponse,
  fetchOnsiteUsers,
  OnsiteUserDetailResponse,
} from 'context/users/onsite_users/_infra/api/onsiteUsers.get';

import { StaffFilterArea } from 'context/users/onsite_users/Staff/_components/StaffFilterArea';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import {
  Pagination,
  usePagerState,
  useFilterAreaContext,
  FilterAreaContext,
} from 'infra/components/UI/Table/Pagination/Pagination';
import { useCheckSelectorWithPagination } from 'infra/components/UI/Table/Pagination/useCheckSelectorWithPagination';

import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';

import { useSearchParams } from 'react-router-dom';
import { StaffRow } from './_components/StaffRow';
import { BulkUpdateBossButton } from './_components/BulkUpdateBossButton';

const abortControllerList: AbortController[] = [];
export const StaffListContext = () => {
  const pagerState = useFilterAreaContext<OnsiteUserDetailResponse>();
  const [searchParams] = useSearchParams();
  const pageNum = parseInt(searchParams.get('page')!, 10);

  useEffect(() => {
    // 以前の通信は破棄
    abortControllerList[0]?.abort();
    abortControllerList.pop();

    const abortController = new AbortController();
    abortControllerList.push(abortController);

    pagerState.doitPaging(pageNum, abortController.signal);
  }, [
    pagerState.searchParams.get('keyword'),
    pagerState.searchParams.get('ordering'),
  ]);

  useEffect(() => {
    if (pagerState.searchParams.size === 0) {
      pagerState.doitPaging(1);
    }
  }, [pagerState.searchParams]);

  const [checkSelectorState, checkSelectorHandler] =
    useCheckSelectorWithPagination<OnsiteUserDetailResponse>({
      allItemsInPage: pagerState.pager.items,
    });

  return (
    <div>
      <PageTitle title="スタッフ一覧" breadcrumbs={[['ホーム', '/']]} />

      <StaffFilterArea />

      <div className="flex items-center justify-between px-[20px] py-[10px] mt-[20px] bg-grayscale-100">
        <div className="flex gap-[10px]">
          {checkSelectorState.size === 0 ? (
            <p className="text-base text-grayscale-700">
              データが選択されていません
            </p>
          ) : (
            <>
              <p className="text-base text-grayscale-800 font-bold">一括操作</p>
              <p className="text-base text-grayscale-700">
                {checkSelectorState.size}件のデータが選択されています
              </p>
            </>
          )}
        </div>
        {checkSelectorState.size === 0 ? (
          // NOTE: ボタンが表示されない状態でも高さを保つように
          <div className="h-[39.2px]" />
        ) : (
          <BulkUpdateBossButton
            onUpdated={() => {
              checkSelectorHandler.clearSelectedItems();
              pagerState.refetchCurrentPage();
            }}
            selectedStaffMap={checkSelectorState.selectedItemMap}
          />
        )}
      </div>

      <Contents isLoading={pagerState?.pager?.isLoading}>
        <div className="min-h-[340px] relative base-scrollbar">
          <Table className="m-w-[650px]">
            <TableHead className="bg-grayscale-500">
              <TableRow>
                <th className="w-[60px] items-center">
                  <Checkbox
                    checked={checkSelectorState.isAllItemInPageSelected}
                    aria-label="選択状態"
                    onChange={checkSelectorHandler.onChangeHeaderCheckbox}
                  />
                </th>
                <th className="w-[185px]">
                  <TableHeaderCell
                    orderValue="last_name"
                    pagerState={pagerState}
                  >
                    スタッフ名
                  </TableHeaderCell>
                </th>

                <th className="w-[135px]">
                  <TableHeaderCell>タイプ</TableHeaderCell>
                </th>
                <th className="w-[52px]">
                  <TableHeaderCell
                    orderValue="occupation__name"
                    pagerState={pagerState}
                  >
                    職種
                  </TableHeaderCell>
                </th>
                <th className="w-[80px]">
                  <TableHeaderCell
                    orderValue="grade__position__name"
                    pagerState={pagerState}
                  >
                    職位
                  </TableHeaderCell>
                </th>
                <th className="w-[52px]">
                  <TableHeaderCell
                    orderValue="grade__name"
                    pagerState={pagerState}
                  >
                    等級
                  </TableHeaderCell>
                </th>
                <th className="w-[130px]">
                  <TableHeaderCell
                    orderValue="shop__name"
                    pagerState={pagerState}
                  >
                    所属店舗
                  </TableHeaderCell>
                </th>
                <th className="w-[80px]">
                  <TableHeaderCell>目標進捗</TableHeaderCell>
                </th>
                <th className="w-[132px]">
                  <TableHeaderCell
                    orderValue="boss__last_name"
                    pagerState={pagerState}
                  >
                    評価者
                  </TableHeaderCell>
                </th>
                <th className="w-[52px]" aria-label="メニューボタンの列" />
              </TableRow>
            </TableHead>
            <TableBody>
              {pagerState?.pager?.items?.map(
                (user: OnsiteUserDetailResponse, index: number) => {
                  return (
                    <StaffRow
                      key={user.id}
                      isChecked={checkSelectorState.selectedItemMap.has(
                        user.id
                      )}
                      onChangeCheckbox={
                        checkSelectorHandler.onChangeRowCheckbox
                      }
                      onsiteUser={user}
                      index={index}
                      isStripedBackgroundColor
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Contents>
      <div className="pt-[40px]">
        <Pagination pagerState={pagerState} />
      </div>
    </div>
  );
};

export const StaffList = () => {
  const pagerState = usePagerState<OnsiteUserListResponse>(
    (
      limit: number,
      offset: number,
      searchParams: URLSearchParams,
      signal?: AbortSignal
    ) => {
      return fetchOnsiteUsers(limit, offset, searchParams, signal);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState?.pager?.isLoading}>
        <StaffListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
