import { BoxProps, Chip, Rating } from '@mui/material';
import { CommitmentCard } from 'context/users/onsite_users/_infra/components/StaffDetails/_components/CommitmentCard';
import { NavigateBeforeIcon } from 'infra/components/UI/Icons/NavigateBeforeIcon';
import { Link } from 'react-router-dom';
import { ResponseObjectiveRecord } from 'context/objective/api/useFetchObjectiveRecords';
import { formatDate } from 'infra/utils/date-fns';
import { AvatarAndName } from 'infra/components/UI/Profiles/AvatarAndName';
import { TimeIcon } from 'infra/components/UI/Icons/TimeIcon';
import { PracticalThinkingCheckbox } from 'infra/components/UI/Icons/PracticalThinkingCheckbox';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { COLORS, STYLES } from 'tailwind.config';
import { colorToRgba } from 'context/mvps/types/mvpCategory';

export const GoalDetailsTab = (props: {
  objectiveRecord: ResponseObjectiveRecord;
  handleBack: () => void;
}) => {
  return (
    <div className="p-[24px]">
      <div className="mb-[24px]">
        <button
          type="button"
          aria-label="戻る"
          onClick={props.handleBack}
          className="flex items-center base-hover"
        >
          <span>
            <NavigateBeforeIcon />
          </span>
          <span className="text-base font-normal text-main-blue">
            マンダラ一覧へ戻る
          </span>
        </button>
      </div>
      <CommitmentCard objectiveRecord={props.objectiveRecord} cardProps={{}} />

      {props.objectiveRecord.basicThinkings.map((basicThinking) => {
        const middleMySelf =
          basicThinking.evaluationResults.find(
            (e) => e.periodType === 'middle' && e.fromUserType === 'myself'
          ) || null;
        const middleBoss =
          basicThinking.evaluationResults.find(
            (e) => e.periodType === 'middle' && e.fromUserType === 'boss'
          ) || null;
        const finalMySelf =
          basicThinking.evaluationResults.find(
            (e) => e.periodType === 'final' && e.fromUserType === 'myself'
          ) || null;
        const finalBoss =
          basicThinking.evaluationResults.find(
            (e) => e.periodType === 'final' && e.fromUserType === 'boss'
          ) || null;
        return (
          <div className="pt-[32px]" key={basicThinking.id}>
            <div className="flex text-center font-bold mb-[30px]">
              <div
                className="inline-block w-[8px] h-[24px] mr-[8px]"
                style={{
                  backgroundColor:
                    basicThinking.basicThinkingMaster.category.color,
                }}
              />
              {basicThinking.basicThinkingMaster.basicThinkingLabel}
            </div>

            <div className="pl-[16px]">
              {basicThinking.practicalThinkings.map((p) => {
                return (
                  <PracticalThinkingCheckbox
                    isChecked={p.hasAchieved || false}
                    isRecentGoal={p.isRecentGoal || false}
                    description={
                      p.practicalThinkingMaster.practicalThinkingLabel
                    }
                  />
                );
              })}
            </div>

            {/* 中間評価 */}
            {props.objectiveRecord.isCompletedMiddleEvaluationByMyself && (
              <div className="mt-[24px] mx-[16px] rounded bg-grayscale-400">
                <div className="flex justify-between items-center p-[20px]">
                  <p className="font-bold">中間評価</p>
                  {/* FIXME: 将来的に折りたたみ入れたい */}
                  {/* <Remove /> */}
                </div>
                <Divider className="mb-[10px]" />
                <div className="px-[20px] py-[30px]">
                  {middleMySelf !== null && (
                    <div className="flex items-center gap-[18px] mb-[20px]">
                      <Chip
                        label="中間自己評価"
                        variant="outlined"
                        size="small"
                        style={{
                          color: COLORS.GRAY_800,
                          backgroundColor: COLORS.GRAY_100,
                          borderRadius: STYLES.BORDER_RADIUS.DEFAULT,
                        }}
                      />
                      <Rating
                        size="large"
                        name="disabled"
                        max={4}
                        value={middleMySelf.score}
                        readOnly
                      />
                    </div>
                  )}

                  {middleBoss !== null && (
                    <>
                      <div className="flex gap-[20px] mb-[16px]">
                        <Chip
                          label="中間上司評価"
                          variant="outlined"
                          size="small"
                          sx={{
                            color: COLORS.GRAY_100,
                            backgroundColor: COLORS.GRAY_700,
                            borderRadius: STYLES.BORDER_RADIUS.DEFAULT,
                          }}
                        />
                        <Rating
                          size="large"
                          name="disabled"
                          max={4}
                          value={middleBoss.score}
                          readOnly
                        />
                      </div>

                      <SpeechBubble
                        marginTop={3.75}
                        marginBottom={2.25}
                        text={middleBoss.comment ?? 'コメントなし'}
                      />

                      <div className="flex mt-[20px] justify-between items-center">
                        <Link
                          to={`/staffs_manage/staffs/${middleBoss.evaluatedBy.id}`}
                        >
                          <AvatarAndName
                            imagePath={middleBoss.evaluatedBy.avatar ?? ''}
                            name={`${middleBoss.evaluatedBy.lastName} ${middleBoss.evaluatedBy.firstName}`}
                          />
                        </Link>
                        <div className="flex items-center text-grayscale-700 text-meta gap-[8px]">
                          <TimeIcon style={{ width: '16px' }} />
                          {formatDate(new Date(middleBoss.createdAt))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            {/* 最終評価 */}
            {props.objectiveRecord.isCompletedMiddleEvaluationByBoss && (
              <div
                className="mt-[24px] mx-[16px] rounded"
                style={{ backgroundColor: colorToRgba(COLORS.MAIN_BLUE, 0.1) }}
              >
                <div className="flex justify-between items-center p-[20px]">
                  <p className="font-bold">最終評価</p>
                  {/* FIXME: 将来的に折りたたみ入れたい */}
                  {/* <Remove /> */}
                </div>
                <Divider className="mb-[10px]" />
                <div className="px-[20px] py-[30px]">
                  {finalMySelf !== null && (
                    <div className="flex items-center gap-[20px] mb-[16px]">
                      <Chip
                        label="最終自己評価"
                        variant="outlined"
                        size="small"
                        sx={{
                          color: COLORS.MAIN_BLUE,
                          backgroundColor: COLORS.GRAY_100,
                          borderRadius: STYLES.BORDER_RADIUS.DEFAULT,
                          borderColor: COLORS.MAIN_BLUE,
                        }}
                      />
                      <Rating
                        size="large"
                        name="disabled"
                        max={4}
                        value={finalMySelf.score}
                        readOnly
                      />
                    </div>
                  )}

                  {finalBoss !== null && (
                    <>
                      <div className="flex items-center gap-[20px] mb-[16px]">
                        <Chip
                          label="最終上司評価"
                          variant="outlined"
                          size="small"
                          sx={{
                            color: COLORS.GRAY_100,
                            backgroundColor: COLORS.MAIN_BLUE,
                            borderWidth: 0,
                            borderRadius: STYLES.BORDER_RADIUS.DEFAULT,
                          }}
                        />
                        <Rating
                          size="large"
                          name="disabled"
                          max={4}
                          value={finalBoss.score}
                          readOnly
                        />
                      </div>

                      {/* <SpeechBubble
                      marginTop={3.75}
                      marginBottom={2.25}
                      text={finalBoss.comment ?? 'コメントなし'}
                    /> */}

                      <div className="flex justify-between items-center">
                        <Link
                          to={`/staffs_manage/staffs/${finalBoss.evaluatedBy.id}`}
                        >
                          <AvatarAndName
                            imagePath={finalBoss.evaluatedBy.avatar ?? ''}
                            name={`${finalBoss.evaluatedBy.lastName} ${finalBoss.evaluatedBy.firstName}`}
                          />
                        </Link>
                        <div className="flex items-center text-grayscale-700 text-meta gap-[8px]">
                          <TimeIcon style={{ width: '16px' }} />
                          {formatDate(new Date(finalBoss.createdAt))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}

      <div className="mt-[40px]">
        <button
          type="button"
          aria-label="戻る"
          onClick={props.handleBack}
          className="flex items-center base-hover"
        >
          <span>
            <NavigateBeforeIcon />
          </span>
          <span className="text-base font-normal text-main-blue">
            マンダラ一覧へ戻る
          </span>
        </button>
      </div>
    </div>
  );
};

export const SpeechBubble = (
  props: BoxProps & {
    text: string;
  }
) => {
  const { text } = props;

  return (
    <div className="w-full">
      <div className="py-[12px] px-[16px] rounded border-1 border-grayscale-500 bg-grayscale-100">
        <p className="text-base whitespace-pre-wrap">{text}</p>
      </div>
    </div>
  );
};
